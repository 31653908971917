import axios from '@/api/apiConfig'
/**
 * product首页数据（多种）
 * @param {
 *
 * } params
 * @returns
 */
export const getSomeProductOfIndex = (params) => {
  return axios.post(`/web/PProduct/findPProduct`, params)
}

/**产品类型及产品名称（全部） */
export const getProductTypeAndName = (params) => {
  return axios.post(`/web/PProduct/findPProductTypeAndName`, params)
}

/**
 * 产品名称及图片列表
 * @param {
 *  pProductAndImageFrom: {
 *   basePage: {
 *       "page":: "string",
 *       "limit":: "string",
 *   },
 *   "classifyType": 0, // 共二级 分类类型(classifyType)为0, 共四级 分类类型为1)
 *   productId: ''
 *  }
 * } params
 * @returns
 */
/**产品类型及产品名称 */
export const getProductList = (params) => {
  return axios.post(`/web/PProduct/findPProductNameImageById`, params)
}

/**产品材质及系列及图片--Shutters Components分类(共四级分类) */
export const getProductListOf4 = (params) => {
  return axios.post(`/web/PProduct/findPProductMaterialSeriesAndImageById`, params)
}

/**邮箱列表 */
export const getEmail = (params) => {
  return axios.post(`/web/email/findEmail`, params)
}

/**
 *发送邮件-留言
 * @param {
 *  pEmailFrom: {
 *      "email": "string",
 *      "message": "string",
 *      "name": "string",
 *      "phone": "string"
 *  }
 * } params
 * @returns
 */

export const sendEmail = (params) => {
  return axios.post(`/web/email/sendEmail`, params)
}

/**
 *发送邮件-订阅
 * @param {
 *  emailAddress: "string"
 * } params
 * @returns
 */
export const sendEmails = (params) => {
  return axios.post(`/web/email/sendEmails`, {}, { params: params })
}

/**
 * add by Hqs  date:2022-06-05
 */
// Gallery 图片
export const getPGallery = (params) => {
  return axios.post(`/web/Gallery/getPGallery`, {}, { params: params })
}

// 首页 图片
export const getPIndexImages = (params) => {
  return axios.post(`/web/index/getPIndexImages`, {}, { params: params })
}

// 产品图片
export const getProductNameAndImages = (params) => {
  return axios.post(`/web/PProduct/getProductNameAndImages`, {}, { params: params })
}
