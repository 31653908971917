<template>
  <div id="myFooter">
    <div class="bg"></div>
    <div class="footer-width-box">
      <div class="menu">
        <router-link to="/About">ABOUT</router-link>
        <router-link to="/Shutters">PRODUCT RANGE</router-link>
        <router-link to="/ShutterComponents">SHUTTER COMPONENTS</router-link>
        <router-link to="/Gallery">GALLERY</router-link>
      </div>
      <div class="contact_us">
        <p class="title">CONTACT</p>
        <p><span>SHANDONG MOONA </span>FURNITURE MANUFACTURING CO . LTD<br />
          TEL: 086 05306161818<br />
          FAX:0086-530-6161718<br />
          Email: moonafurniture@vip.163.com<br />
          <span class="email-2">sales@moonashutters.com</span><br />
          Add: Zhuangzhai Town , Cao County, Heze City, Shandong Province, China
        </p>
      </div>
      <div class="send_email">
        <h2>Fill in the information</h2>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <div class="left">
            <el-form-item prop="name">
              <el-input v-model="ruleForm.name" placeholder="Name" class="width578"></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input v-model="ruleForm.email" placeholder="Email" class="width578"></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input v-model="ruleForm.phone" placeholder="Mobile phone" class="width578"></el-input>
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item prop="message">
              <el-input type="textarea" v-model="ruleForm.message" placeholder="Message" class="width578 textarea" resize="none"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <el-button class="submit-btn" @click="submitForm('ruleForm')">SUBMIT</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from '@pm'
export default {
  name: 'myFooter',
  data() {
    return {
      emailAddress: '',
      ruleForm: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      rules: {
        name: [
          { required: true, message: 'Please enter your name', trigger: 'blur' },
          { min: 3, max: 5, message: '3 to 5 characters in length', trigger: 'blur' }
        ],
        email: [{ required: true, message: 'Please fill in the email address', trigger: 'blur', type: 'email' }],
        phone: [{ required: true, message: 'Please fill in your mobile number', trigger: 'blur' }],
        message: [{ required: true, message: 'Please fill in the information', trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          Api.sendEmail(this.ruleForm).then((result) => {
            loading.close()
            if (result.data.code == 200) {
              this.$refs[formName].resetFields()
              alert('submit success!')
            } else {
              alert(result.data.msg)
              return false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
#myFooter {
  height: 334px;
  background: rgba(34, 23, 21, 1);
  .bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 330px;
    background: rgba(34, 23, 21, 1);
  }
  .footer-width-box {
    width: 1440px !important;
    height: 260px;
    margin: 0 auto;
    padding: 0 20px;
    padding-top: 40px;
    display: flex;
    align-items: baseline;
    .menu {
      width: 242px;
      height: 210px;
      margin-right: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        font-size: 14px; // 19px;
        font-weight: normal;
        line-height: 30px;
        color: #ffffff;
        margin-bottom: 34px;
      }
    }
    .contact_us {
      width: 653px;
      height: 210px;
      font-size: 14px; // 19px;
      font-weight: 300;
      line-height: 36px;
      color: #ababab;
      margin-right: 39px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 14px; // 19px;
        font-weight: 500;
        line-height: 26px;
        color: #ffffff;
      }
      p {
        font-size: 14px; // 19px;
        font-weight: 300;
        line-height: 40px;
        color: #ababab;
        .email-2 {
          margin-left: 40px;
        }
      }
    }
    .send_email {
      width: 640px;
      h2 {
        font-size: 14px; // 19px;
        font-weight: 300;
        line-height: 26px;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }
  }
  .submit-btn {
    display: block;
    width: 226px;
    height: 45px;
    background: rgba(149, 236, 105, 1);
    border-radius: 8px;
    border: none;
    font-size: 19px;
    font-family: DINNextLTPro-Regular;
    line-height: 26px;
    color: #ffffff;
    font-weight: normal;
    margin: 0 auto;
  }
}
</style>
<style lang="less">
#myFooter {
  .demo-ruleForm {
    display: flex;
    .right {
      margin-left: 15px;
    }
    .el-form-item {
      .el-input {
        width: 280px;
        height: 41px;
        border: 1px solid #ffffff;
        border-radius: 4px;
      }
      .el-form-item__error {
        top: 100%;
      }
      .el-textarea {
        width: 280px;
        height: 167px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        .el-textarea__inner {
          height: 100% !important;
        }
      }
    }
  }
}
</style>