<template>
  <div id="Shutters">
    <MyHeader />
    <div class="block1">
      <h3>CONTACT</h3>
      <div class="content">
        <img src="@/assets/images/contact.png" />
        <div class="right">
          <p>With premium quality, innovation and sustainability,
            we established long-term business cooperation with our customers in Europe, USA,
            Australia and many other areas. “One world, one shutter”,
            we are looking forward to serve more customers all over the world.
            Email: moonafurniture@vip.163.com</p>
        </div>
      </div>
    </div>
    <div class="block2">
      <div class="content">
        <div class="contact-map">
          <el-amap class="amap-box" :zoom="zoom" :center="center" :mapStyle="mapStyle">
            <el-amap-marker :position="[115.231879,35.039153]" :icon="icon"></el-amap-marker>
          </el-amap>
        </div>
        <div class="right">
          <p><span>SHANDONG MOONA </span>FURNITURE MANUFACTURING CO . LTD<br />
            TEL: 086 05306161818<br />
            FAX:0086-530-6161718<br />
            Email: moonafurniture@vip.163.com<br />
            <label>sales@moonashutters.com</label><br />
            Add: Zhuangzhai Town , Cao County, Heze City, Shandong Province, China
          </p>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'
export default {
  name: 'Shutters',
  components: { MyHeader, MyFooter },
  data() {
    return {
      center: [115.231879, 35.039153], //地图中心点坐标
      zoom: 16, //初始化地图显示层级
      mapStyle: 'amap://styles/8b6be8ec497009e17a708205348b899a', //设置地图样式
      icon: ''
    }
  }
}
</script>
<style lang="less" scoped>
#Shutters {
  .block1 {
    width: 1440px;
    height: 645px;
    margin: 90px auto 0;
    h3 {
      // color: #1e1e1e;
      text-align: center;
      font-size: 48px;
      font-family: DINNextLTPro-Medium;
      line-height: 68px;
      color: #3b3b3b;
    }
    .content {
      display: flex;
      align-items: center;
      > img {
        width: 678px;
        height: 519px;
      }
      .right {
        width: 802px;
        height: 452px;
        background: rgba(221, 239, 236, 1);
        border-radius: 10px;
        display: flex;
        align-items: center;
        p {
          font-family: DINNextLTPro-Regular;
          padding-left: 18px;
          border-radius: 10px;

          width: 759px;
          height: 253px;
          font-size: 20px;
          font-weight: 300;
          line-height: 72px;
          color: #070707;
        }
      }
    }
  }
  .block2 {
    width: 1440px;
    height: 645px;
    margin: 0 auto 50px;
    .content {
      display: flex;
      align-items: center;
      .contact-map {
        width: 678px;
        height: 557px;
        margin-top: 80px;
      }
      .right {
        width: 738px;
        height: 338px;
        font-size: 20px;
        font-weight: 300;
        line-height: 72px;
        color: #070707;
        display: flex;
        align-items: center;
        p {
          font-family: DINNextLTPro-Regular;
          padding-left: 18px;
          border-radius: 10px;

          width: 759px;
          height: 253px;
          font-size: 20px;
          font-weight: 300;
          line-height: 72px;
          color: #070707;
          span {
            font-weight: normal;
          }
          label {
            margin-left: 60px;
            color: #070707;
          }
        }
      }
    }
  }
}
</style>