<template>
  <!-- <div v-bind:class="{ active: isActive, 'size-30': isSuccess }">根据property: 'isActive'动态切换'active'样式是否显示</div> -->

  <div id="nav" :class="[hasLinkBtn ? 'IndexAbout' : '', isGallery ? 'PageGallery' : '', isContact ? 'pageContact' : '']">
    <!-- <div id="nav" class="IndexAbout"> -->
    <div class="nav_block">
      <div class="left">
        <img src="@/assets/ico.png">
      </div>
      <div class="right">
        <ul>
          <li v-for="(item,i) in list" :key="i">
            <router-link :to="item.url" :class="url==item.url ? 'font active' : 'font'">{{item.name}}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-link v-if="hasLinkBtn" class="link-btn shutters-btn" to="/Shutters">Learn More About Shutters</router-link>
    <router-link v-if="hasLinkBtn" class="link-btn mooona-btn" to="/TradeArea?hashName=AboutMoona">Learn More About Moona</router-link>
  </div>
</template>
<script>
export default {
  name: 'Index',
  data() {
    return {
      hasLinkBtn: false,
      isGallery: false,
      isContact: false,
      list: [
        {
          name: 'ABOUT',
          url: '/About',
          font: 'font3'
        },
        {
          name: 'PRODUCT RANGE',
          url: '/Shutters',
          font: 'font3'
        },
        {
          name: 'SHUTTER COMPONENTS',
          url: '/ShutterComponents',
          font: 'font2'
        },
        {
          name: 'GALLERY',
          url: '/Gallery',
          font: 'font2'
        },
        // {
        //   name: 'Trade Area',
        //   url: '/TradeArea',
        //   font: 'font1'
        // },
        {
          name: 'CONTACT',
          url: '/Contact',
          font: 'font1'
        }
      ]
    }
  },
  computed: {
    url() {
      this.hasLinkBtn = false
      this.isGallery = false
      this.isContact = false
      let router_path = this.$route.path
      if (router_path === '/About') {
        this.hasLinkBtn = true
      } else if (router_path === '/Gallery') {
        this.isGallery = true
      } else if (router_path === '/Contact') {
        this.isContact = true
      }
      return router_path
    }
  }
}
</script>
<style lang="less" scoped>
#nav {
  position: relative;
  width: 100%;
  height: 105px;
  // height: 896px;
  // background-image: url('../assets/images/home-02.png');
  // background-size: 100% 100%;
  // &.IndexAbout {
  //   background: none;
  // }
  // &.PageGallery {
  //   background-image: url('../assets/images/gallery-banner.png');
  // }
  // &.pageContact {
  //   height: 105px;
  //   background: none;
  // }
  .nav_block {
    width: 1440px;
    height: 105px;
    line-height: 105px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      width: 386px;
      img {
        width: 386px;
        height: 105px;
        float: left;
        margin-top: 12px;
      }
    }
    .right {
      ul {
        display: flex;
        align-items: center;
        li {
          padding: 0 25px;
          a {
            font-size: 20px;
            font-family: STHeiti TC;
            color: #000000;
            white-space: nowrap;
            &.active {
              color: #070707;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .link-btn {
    display: block;
    position: absolute;
    bottom: 28px;
    height: 60px;
    line-height: 60px;
    background: rgba(216, 216, 216, 0.39);
    border-radius: 15px;
    font-size: 29px;
    font-family: Yuanti SC;
    font-weight: bold;
    color: #000000;
    padding: 0 20px;
    &.shutters-btn {
      left: 90px;
    }
    &.mooona-btn {
      right: 90px;
    }
  }
}
</style>